@import '../../common.scss';

.home {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.home--center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__howto {
    @extend .home--center;
    font-size: 1.4rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.howto__step {
    @extend .home--center;
}

.howto__step__icon {
    @extend .home--center,
    .secondary-accent-text;
    font-size: 2rem;
}

.howto__step__icon--mobile {
    display: none;
}

.home__target {
    @extend .home--center;
    font-size: 1.1rem;
}

.howto__link {
    @extend .text-transition;
}

.howto__link:hover {
    color: $primary-text;
}

.howto__code {
    font-size: 1.2rem;
    padding: 0.75rem 1rem;
    cursor: pointer;
}

.home__description {
    margin: 4rem 0;
}

.howto__code__shell-prompt {
    @extend .secondary-accent-text;
    cursor: pointer;
}

@media (max-width: $mobile) {
    .home__howto {
        display: flex;
        flex-direction: column;
    }

    .howto__step__icon {
        display: none;
    }

    .howto__step__icon--mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $secondary-accent;
        font-size: 2rem;
    }
}
