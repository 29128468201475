@import 'reset.css';
@import 'common.scss';

img {
  box-shadow: 1px 1px 10px rgba(211, 138, 138, 1);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* end of css reset */

html {
  padding: 0 1rem 1rem 1rem;
  background: $primary-background;
  color: $primary-text;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 1.618rem;
  font-weight: 600;
  text-align: center;
  color: $primary-text;
  text-decoration: none;
}

body {
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.5em;
  font-weight: 400;
}

a {
  color: $primary-accent;
  font-weight: 600;
}

b {
  font-weight: 600;
}

p {
  margin: 16px 0;
}

code {
  color: $primary-accent;
  background-color: $secondary-background;
  text-align: left;
  font-size: 1rem;
  border-radius: 3px;
  margin: 0 2px;
  padding: 5px 10px;
  white-space: pre-wrap;
  font-weight: 400;
  word-wrap: anywhere;
}

.bold {
  font-weight: 600;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 20px 0;
  border-radius: 3px;
}

.info {
  max-width: 500px;
}

.highlight {
  color: $primary-accent;
}

.italic {
  font-style: italic;
}

select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  font-size: 1rem;
}
