@import '../../common.scss';

.license--center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.license {
  @extend .license--center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  gap: 4rem;
  flex-wrap: wrap;

  &__infrigement {
    font-size: 1rem;
  }
}
