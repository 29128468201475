@import '../../common.scss';

.documentation--center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.documentation {
  @extend .documentation--center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  gap: 4rem;
  flex-wrap: wrap;
}

.documentation__item {
  font-size: 1rem;

  code {
    cursor: pointer;
  }
}
