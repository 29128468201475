@import '../../common.scss';

.spell-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem 0;
    background-color: $primary-background;
}

.spell-item__title {
    @extend .secondary-accent-text;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.spell-item__description {
    margin-bottom: 0.5rem;
}

.spell-item__example {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.spell-item__author {
    @extend .secondary-accent-text;
}

.spell-item__bottom {
    font-size: 0.75rem;
}

.spell-item__separator {
    @extend .secondary-accent-text;
}

.spell-item__shell-prompt {
    @extend .secondary-accent-text;
    cursor: pointer;
}