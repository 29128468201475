@import '../../common.scss';

.search-bar {
    background-color: $secondary-background;
    border-radius: 3px;
    border: none;
    color: $primary-text;
    padding: 0.5rem 1rem;
    flex-grow: 1;

    &__container {
        display: flex;
        flex-grow: 1;
    }

    &:focus-visible {
        outline: 1px solid $secondary-accent;
    }
}