@import '../../common.scss';

.search__spells {
    margin-top: 2rem;
}

.search__not-found {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    flex-direction: column;
    text-align: center;
}