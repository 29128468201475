@import '../../common.scss';

.add--center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add {
  @extend .add--center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  gap: 4rem;
  flex-wrap: wrap;

  &__personal {
    font-size: 0.75rem;
  }
}
