@import '../../common.scss';

.install--center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.install {
  @extend .install--center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  gap: 4rem;
  flex-wrap: wrap;

  &__personal {
    font-size: 0.75rem;
  }

  &__instruction {
    line-height: 2rem;

    code {
      cursor: pointer;
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
}

.install--alternative {
  font-size: 1rem;
}
