@import '../../common.scss';

.install-card {
  padding: 1.5rem;
  border: 1px solid $secondary-accent;
  border-radius: 3px;
  cursor: pointer;
  @extend .text-transition;
  min-width: 100px;
  min-height: 100px;
  text-align: center;

  &:hover {
    background-color: $secondary-background;

    .install-card__title {
      color: $secondary-accent;
    }
  }

  &__icon {
    font-size: 3rem;

    &-container {
      padding: 1rem 0;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }
}
