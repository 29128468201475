@import 'breakpoints.scss';

.text-transition {
    transition: color .1s ease-in-out, background-color .1s ease-in-out;
}

$accent-1: #d38a8a;
$accent-2:#AEADF0;
$accent-3: #FDE74C;
$accent-4: #5BC0EB;
$accent-5: #E55934;
$accent-6: #0CA4A5;
$accent-7: #0892A5;
$accent-8: #999AC6;
$accent-9: #B8BACF;
$accent-10: #F56960;
$accent-11: #F3C98B;
$accent-12: #E6AF2E;
$primary-accent: $accent-3;
$secondary-accent: $accent-2;

$primary-text: #eee;
$primary-background: #030303;
$secondary-background: #1d1d1d;

.secondary-accent-text {
    color: $secondary-accent;
}