@import '../../common.scss';

.footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 6rem;
}

.footer__section {
    display: flex;
    flex-direction: row;
    gap: 0.5rem
}

.footer__section__item {
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
}

.footer__section__link {
    @extend .footer__section__item,
    .text-transition;
    border-radius: 3px;
}

.footer__section__link:hover {
    background-color: $primary-text;
    color: $primary-background !important;
}

.footer__section__header {
    @extend .footer__section__item;
    font-weight: 700;
}

.footer__section {
    font-size: 0.75rem;
}

.footer__author {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;

    &__link {
        @extend .text-transition;

        &:hover {
            color: $primary-text !important;
        }
    }
}

@media (max-width: $mobile) {
    .footer {
        flex-direction: column;
    }

    .footer__section {
        margin-bottom: 1.5rem;
    }
}