@import '../../common.scss';

.navbar {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: $primary-background;
    gap: 1rem;
}

.navbar__title-with-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
    padding-bottom: 0.5rem;
}

.navbar__title__icon {
    display: inline;
}

.navbar__items {
    display: flex;
    flex-direction: row;
    gap: 0.5rem
}

.navbar__item {
    @extend .text-transition;
    justify-content: space-between;
    padding: 0.25rem 1rem;
    border-radius: 3px;
    font-size: 1rem;
}

.navbar__item:hover {
    background-color: $primary-text;
    color: $primary-background !important;
}

.navbar__title--primary-half {
    @extend .text-transition;

    color: $primary-accent;

    &:hover {
        color: inherit;
    }
}

.navbar__title--secondary-half {
    @extend .text-transition;

    color: $secondary-accent;

    &:hover {
        color: inherit;
    }
}

.navbar__subtitle {
    font-size: 0.75rem;
    text-align: center;
}

.navbar__burger-menu {
    display: none;
}

.navbar__subtitle--mobile {
    display: none;
}

.navbar__items-container--mobile {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    padding: 1rem 0rem;
    flex-direction: column;
    display: flex;
    background-color: black;
    gap: 0;
}

@media (max-width: $mobile) {
    .navbar {
        flex-direction: column;
    }

    .navbar__burger-menu {
        display: block;
        color: white;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 40px;
        width: 40px;
    }

    .navbar__title-with-search {
        flex-wrap: wrap;
    }

    .navbar__items-container {
        display: none;
    }
}